import "parallax_content";
import "gsap/TweenMax";
import "gsap/TweenLite";
import "gsap/CSSPlugin";

(function ($) {
	"use strict";

	$(".move-down").parallaxContent({ shift: 22 });
	$(".move-up").parallaxContent({ shift: 22, direction: "reverse" });
})(jQuery);
